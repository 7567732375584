.footer {
  &__container {
    display: flex;
    flex-flow: row nowrap;
    .footer__content {
      display: flex;
      flex-flow: row nowrap;
      align-items: start;
      justify-content: space-between;
      flex: 1;
      margin-bottom: 132px;
      .footer__logo {
        .logo-link {
          display: flex;
          flex-flow: row nowrap;
          margin-bottom: 16px;
          .logo {
            width: 28px;
            height: 28px;
            margin: 8px 8px 0px 0px;
          }
          .name {
            font-family: var(--font-primary1);
            font-size: xx-large;
            color: var(--primary_color2);
            line-height: 140%;
          }
        }
        p {
          font-family: var(--font-primary2);
          font-size: 18px;
          font-weight: 400;
          color: var(--sub-text-color);
          line-height: 150%;
        }
        .media__icon-list {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          margin-top: 24px;
          margin-left: -24px;
          .icon-link {
            margin-left: 24px;
            .icon {
              font-size: 16px;
            }
          }
        }
      }
      h4 {
        font-family: var(--font-primary1);
        font-size: 20px;
        font-weight: 500;
        color: var(--primary_color2);
        line-height: 125%;
        letter-spacing: 0.2px;
        padding-left: 36px;
      }
      .list {
        margin-top: 24px;
        padding-left: 36px;
        .item {
          margin-bottom: 24px;
          a {
            text-decoration: none;
            font-family: var(--font-primary2);
            font-size: 16px;
            font-weight: 500;
            color: var(--sub-text-color);
          }
        }
      }
    }
  }
  .copy-right {
    background-color: #ffffff;
    padding: 24px;
    text-align: center;
    border-top: 1px solid #e7e7e7;
    span {
    }
  }
}
