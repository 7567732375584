* {
  box-sizing: inherit;
}
body {
  margin: 0;
  padding: 0;
}

:root {
  --primary_color1: #cda274;
  --primary_color2: #292f36;
  --primary_color3: #f4f0ec;
  --sub-text-color: #4d5053;
  --font-primary1: "DM Serif Display", serif;
  --font-primary2: "Jost", sans-serif;
}

.main__container {
  width: 100%;
  min-height: 100vh;
  background-image: url("./Assets/background.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
}
