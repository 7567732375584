.project__item {
  margin-bottom: 56px;
  .project__thumb {
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .project__description {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    .content {
      h4 {
        font-family: var(--font-primary1);
        font-size: 20px;
        font-weight: 500;
        color: var(--secondary-color);
        line-height: 125%;
        letter-spacing: 0.2px;
      }
      span {
        font-family: var(--font-secondary);
        font-size: 16px;
        font-weight: 400;
        color: var(--sub-text-color);
        line-height: 150%;
        letter-spacing: 0.1px;
      }
    }
  }
}
